<template>
    <div class="container-utility-detail">
        <div class="header-utility-detail">{{$t('utility')}}</div>
        <div class="wrap-utility-detail">
            <div>
                <div class="image-utility-detail" :style="`background-image : url('${returnImg(utilityData.UtilityImg[0])}')`"></div>
                <div class="info-utility-deal">{{$t('tall_how_to_buy')}}</div>
            </div>
            <div>
                <div class="wrap-header-utility">
                    <div class="header-utility">
                        <div class="icon-collection-utiltity" :style="`background-image : url('${returnImg(utilityData.collectionImg[0])}')`"></div>
                        <div class="title-utility-detail">{{utilityData.c_title}}</div>
                    </div>
                    <div class="box-user-utility">
                        <div class="icon-director-utility" :style="`background-image : url('${returnImg(utilityData.directorProfile[0])}')`"></div>
                        <div class="name-director-utility">{{utilityData.director_nick}}</div>
                    </div>
                </div>
                <div class="category-utility-detail">{{utilityData.uc_name}}</div>
                <div class="name-utility-detail">{{utilityData.u_name}}</div>

                <div class="icon-division-utuility" v-if="showSellCountdown(2)"
                         :class="returnState(utilityData.u_is_use, utilityData.u_use_start_date, utilityData.u_use_end_date)">
                        {{
                            $t(returnStateText(utilityData.u_is_use, utilityData.u_use_start_date, utilityData.u_use_end_date))
                        }}
                    </div>

                <div class="info-utility-common" v-if="returnSellState() != 2">
                    <div class="title-utility-info">{{$t('sell_time')}}</div>
                    <div class="content-utility-info">{{returnDateTime(utilityData.u_sell_start_date)}} ~ {{returnDateTime(utilityData.u_sell_end_date)}}</div>
                </div>                
                <div class="info-utility-common" v-if="returnSellState() != 2">
                    <div class="title-utility-info">{{$t('sell_time')}}</div>
                    <div class="content-utility-info">{{returnDateTime(utilityData.u_sell_start_date)}} ~ {{returnDateTime(utilityData.u_sell_end_date)}}</div>
                </div>
                <div class="info-utility-common">
                    <div class="title-utility-info">{{$t('using_time')}}</div>
                    <div class="content-utility-info">{{returnDateTime(utilityData.u_use_start_date)}} ~ {{returnDateTime(utilityData.u_use_end_date)}}</div>
                </div>
                <div class="info-utility-common">
                    <div class="title-utility-info">{{$t('using_cnt')}}</div>
                    <div class="content-utility-info">{{utilityData.u_use_total}}</div>
                </div>
                <div class="info-utility-common">
                    <div class="title-utility-info">{{$t('total_cnt')}}</div>
                    <div>
                        <div class="content-utility-info pb8" v-if="returnSellState() != 2">{{`${utilityData.selled_cnt ?utilityData.selled_cnt:0 } / ${utilityData.u_publish_total}`}}</div>
                        <div class="content-utility-info pb8" v-else >{{`${utilityData.selled_cnt ?utilityData.selled_cnt:0 }`}}</div>
                        <div class="box-progress-utility" v-if="returnSellState() != 2">
                            <div class="inner-progress-utility" :style="`width: ${returnPercent(utilityData.selled_cnt ?utilityData.selled_cnt:0,utilityData.u_publish_total)}%`"></div>
                        </div>
                    </div>
                </div>
                <div class="box-countdown-utility" v-if="returnSellState() == 0">
                    <div class="title-countdown-utility" v-html="$t('left_sell_time')"></div>
                    <div class="counts-countdown-utility" id="countdown"></div>
                    <div class="date-countdown-utility">
                        <div class="pr32">{{ $t('sell_tile') }}</div>
                        <div>{{ returnDateTime(utilityData.u_sell_start_date) }}</div>
                    </div>
                </div>
                <div class="box-cost-common" v-if="returnSellState() != 2">
                    <div class="info-cost-common">{{$t('n_buy')}}</div>
                    <div>
                        <div>
                            <div class="icon-common"></div>
                            <div class="text-cost-common">{{utilityData.u_price}} Mg</div>
                        </div>
                        <div class="won-cost-common">(₩ {{utilityData.u_price}}0)</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-utility-detail">{{$t('utility_ex')}}</div>
        <div class="explain-utility-detail">{{utilityData.u_detail}}</div>
    </div>
</template>


<script>
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";

export default {
    name: "UtilityDetailDefaultLayout",
    mixins: [dateMixins],
    components: { },
    props: {
        utilityData: {
            default: () => {}
        }
    },
    data() {
        return {

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        if (!util.isEmpty(this.utilityData)) {
            if (this.showSellCountdown(0)) {
                this.intervalId = setInterval(() => {
                    this.getDifferenceDday(this.utilityData.u_sell_start_date)
                }, 1000);
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        returnImg(data) {
            if (!util.isEmpty(data)) {
                return data.org_url;
            } else {
                return '';
            }
        },
        showSellCountdown(value) {
            if (this.returnSellState() === value) {
                return true
            } else {
                return false;
            }
        },
        returnSellState() {
            let now = this.returnDateTime(new Date());
            let start = this.returnDateTime(this.utilityData.u_sell_start_date);
            let end = this.returnDateTime(this.utilityData.u_sell_end_date);

            if (start > now) {
                return 0; //판매대기
            } else if (start < now && end > now) {
                return 1; //판매중
            } else if (end < now) {
                return 2; //판매종료
            } else {
                console.log('error')
            }
        },
        returnPercent(value, max) {
            if (!util.isEmpty(value) && !util.isEmpty(max)) {
                return (value / max) * 100;
            }
        },
        returnState(state, start, end) {
            let now = this.returnDateTime(new Date());

            if (state == 1) {
                if (start > now) {
                    return 'waiting'; // 사용대기
                } else if (start < now && end > now) {
                    return 'possible'; // 사용가능
                } else if (end < now) {
                    return 'already'; //기한만료
                }
            } else {
                return 'used'; // 사용완료
            }
        },
        returnStateText(state, start, end) {
            let now = this.returnDateTime(new Date());

            if (state == 1) {
                if (start > now) {
                    return 'use_await'; // 사용대기
                } else if (start < now && end > now) {
                    return 'use_possible'; // 사용가능
                } else if (end < now) {
                    return 'use_end_already'; //기한만료
                }
            } else {
                return 'use_fin'; // 사용완료
            }
        },
    },
    watch: {
    },
}
</script>
